import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonRouterLink, IonFooter, IonList, IonItem, IonLabel, IonInput, IonText } from "@ionic/react";
import { useState } from "react";
import { SubMerchant, SubMerchantAccount, MerchantEmailAddress, UpdateSubmerchantAsync } from "../../../lib";
import { useParams } from "react-router";
import { UserViewParams } from "../../User";

export type SubMerchantModalProps = {
    selectedSubmerchant: SubMerchant | undefined;
    onClose: () => void;
    onChange: () => void;
}

export default function SubMerchantModal({ selectedSubmerchant, onClose, onChange }: SubMerchantModalProps) {
    const { userId } = useParams<UserViewParams>();
    const [selectedSubMerchantAccount, setSelectedSubMerchantAccount] = useState<SubMerchantAccount>();
    const [newRole, setNewRole] = useState<string>();

    async function removeRole(role: string): Promise<void> {
        try {
            var submerchant = selectedSubmerchant!;
            var account = submerchant.accounts!.find(a => a.accountId === selectedSubMerchantAccount!.accountId);
            account!.roles = account!.roles!.filter(r => r !== role);
            await UpdateSubmerchantAsync(userId!, submerchant);
        } finally {
            setNewRole(undefined);
            setSelectedSubMerchantAccount(undefined);
            onChange();
        }
    }

    async function addRole(): Promise<void> {
        try {
            var submerchant = selectedSubmerchant!;
            var account = submerchant.accounts!.find(a => a.accountId === selectedSubMerchantAccount!.accountId);
            account!.roles ??= [];
            account!.roles!.push(newRole!);
            await UpdateSubmerchantAsync(userId!, submerchant);
        } finally {
            setNewRole(undefined);
            setSelectedSubMerchantAccount(undefined);
            onChange();
        }
    }

    return <>
        <IonModal isOpen={!!selectedSubmerchant} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Submerchant Accounts</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Account ID</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Roles</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedSubmerchant?.accounts?.map((account) => (
                            <tr key={account.accountId}>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    <IonRouterLink href={`/users/${account.accountId}`} target="_blank">
                                        {account.accountId}
                                    </IonRouterLink>
                                </td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    {account.roles?.join(', ')}
                                </td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    <IonButton onClick={() => setSelectedSubMerchantAccount(account)}>Edit</IonButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </IonContent>
            {selectedSubMerchantAccount && <IonFooter style={{ padding: "15px" }}>
                <IonToolbar>
                    <IonText color="medium" className="ion-text-center">
                        <small>
                            Example roles: admin
                        </small>
                    </IonText>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setSelectedSubMerchantAccount(undefined)}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonList>
                    {selectedSubMerchantAccount.roles?.map((role) => (
                        <IonItem>
                            <IonLabel>{role}</IonLabel>
                            <IonButton onClick={() => removeRole(role)}>Remove</IonButton>
                        </IonItem>
                    ))}
                </IonList>
                <IonInput placeholder='Type new role name here' value={newRole} onIonChange={(e) => setNewRole(e.detail.value!)} />
                <IonButton onClick={addRole}>Add</IonButton>
            </IonFooter>}
        </IonModal>
    </>;
}
