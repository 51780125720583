import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonInput, IonList, IonItem, IonLabel, IonText, IonCheckbox, IonSpinner } from "@ionic/react";
import { useParams } from "react-router";
import { UserViewParams } from "../../User";
import { FileDragDropUpload, FileDragDropUploadRef } from "../../controls";
import { useState, useEffect, useRef } from "react";
import { SubMerchantCreate, UploadSubMerchantsAsync } from "../../../lib/data/Merchant";

export type SubMerchantImportModalProps = {
    onClose: () => void;
}

export default function SubMerchantImportModal({ onClose }: SubMerchantImportModalProps) {
    const { userId } = useParams<UserViewParams>();
    const fileUploadRef = useRef<FileDragDropUploadRef>(null);
    const [hasHeaderRow, setHasHeaderRow] = useState(true);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [previewData, setPreviewData] = useState<{ name: string; email: string; roles: string; }[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const parseCSV = (file: File) => {
        setError(null);
        const reader = new FileReader();
        
        reader.onload = (event) => {
            try {
                if (!event.target?.result) {
                    throw new Error('Failed to read file content');
                }

                const text = event.target.result as string;
                if (!text.trim()) {
                    throw new Error('File is empty');
                }

                let rows = text.split('\n')
                    .map(row => row.split(',').map(cell => cell.trim()));

                // Skip header row if option is selected
                if (hasHeaderRow) {
                    rows = rows.slice(1);
                }

                const parsedRows = rows
                    .filter(row => row.length >= 3 && row.some(cell => cell))
                    .slice(0, 4)
                    .map((row, index) => {
                        if (row.length < 3) {
                            throw new Error(`Row ${index + 1} is missing required columns`);
                        }
                        if (!row[1].includes('@')) {
                            throw new Error(`Row ${index + 1} contains an invalid email address`);
                        }
                        return {
                            name: row[0] || '',
                            email: row[1] || '',
                            roles: row[2] || ''
                        };
                    });

                if (parsedRows.length === 0) {
                    throw new Error('No valid data found in file');
                }

                setPreviewData(parsedRows);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to parse CSV');
                setPreviewData([]);
                fileUploadRef.current?.stopSpinning();
            }
        };

        reader.onerror = () => {
            setError('Failed to read file');
            setPreviewData([]);
            fileUploadRef.current?.stopSpinning();
        };

        reader.readAsText(file);
    };

    useEffect(() => {
        if (file) {
            parseCSV(file);
        }
    }, [file, hasHeaderRow]);

    const handleUpload = async () => {
        if (!file || !previewData.length) return;
        
        try {
            setIsUploading(true);
            setError(null);
            
            // extract the full data from the file and create submerchants
            var submerchants = await ExtractSubMerchantsFromCSV(file, hasHeaderRow);

            // upload the submerchants
            var response = await UploadSubMerchantsAsync(userId, submerchants);
            if (response.success) {
                onClose(); // Close modal on success
            } else {
                setError(response.message || 'Failed to upload submerchants');
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to upload submerchants');
        } finally {
            setIsUploading(false);
        }
    };

    return <>
        <IonModal isOpen={true}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Import Submerchants</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose}>Cancel</IonButton>
                        <IonButton 
                            strong={true}
                            disabled={!previewData.length || isUploading}
                            onClick={handleUpload}
                        >
                            {isUploading ? 'Uploading...' : 'Upload'}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p>Import submerchants from a CSV file.</p>
                <FileDragDropUpload 
                    ref={fileUploadRef}
                    onChange={(file) => setFile(file)} 
                />
                
                <IonItem className="ion-margin-top">
                    <IonLabel>File has header row</IonLabel>
                    <IonCheckbox 
                        checked={hasHeaderRow}
                        onIonChange={e => setHasHeaderRow(e.detail.checked)}
                    />
                </IonItem>
                
                {error && (
                    <div className="ion-padding-top">
                        <IonText color="danger">
                            <p><strong>Error:</strong> {error}</p>
                        </IonText>
                    </div>
                )}
                
                {previewData.length > 0 && (
                    <div className="ion-padding-top">
                        <h4>Preview (First 4 rows):</h4>
                        <IonList>
                            {previewData.map((row, index) => (
                                <IonItem key={index}>
                                    <IonLabel>
                                        <h2>{row.name}</h2>
                                        <p>Email: {row.email}</p>
                                        <p>Roles: {row.roles}</p>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        
                        <div className="ion-padding-top ion-text-center">
                            <IonButton
                                expand="block"
                                disabled={isUploading}
                                onClick={handleUpload}
                            >
                                {isUploading ? (
                                    <>
                                        <IonSpinner name="crescent" className="ion-margin-end" />
                                        Uploading...
                                    </>
                                ) : (
                                    'Upload Submerchants'
                                )}
                            </IonButton>
                        </div>
                    </div>
                )}
            </IonContent>
        </IonModal>
    </>;
}

async function ExtractSubMerchantsFromCSV(file: File, hasHeaderRow: boolean): Promise<SubMerchantCreate[]> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = (event) => {
            try {
                if (!event.target?.result) {
                    throw new Error('Failed to read file content');
                }

                const text = event.target.result as string;
                if (!text.trim()) {
                    throw new Error('File is empty');
                }

                let rows = text.split('\n')
                    .map(row => row.split(',').map(cell => cell.trim()));

                // Skip header row if option is selected
                if (hasHeaderRow) {
                    rows = rows.slice(1);
                }

                const submerchants = rows
                    .filter(row => row.length >= 3 && row.some(cell => cell)) // Skip empty rows
                    .map((row, index) => {
                        if (row.length < 3) {
                            throw new Error(`Row ${index + 1} is missing required columns`);
                        }
                        if (!row[1].includes('@')) {
                            throw new Error(`Row ${index + 1} contains an invalid email address`);
                        }
                        return {
                            name: row[0] || '',
                            email: row[1] || '',
                            roles: (row[2] || '').split(',').map(role => role.trim()).filter(role => role)
                        } as SubMerchantCreate;
                    });

                if (submerchants.length === 0) {
                    throw new Error('No valid data found in file');
                }

                resolve(submerchants);
            } catch (err) {
                reject(err);
            }
        };

        reader.onerror = () => {
            reject(new Error('Failed to read file'));
        };

        reader.readAsText(file);
    });
}
