import { ApiGet, ApiPost, ApiPostResponse, ApiPut, ApiResponse } from "..";
import { Partner } from "./PartnerData";

export type Merchant = {
    id: string,
    accountNumber: string,
    sortCode: string,
    accessTokenAvailable: string,
    vatnumber: string,
    accountHolderName: string,
    address: Address,
    name: string,
    apiKeyId: string,
    settings: MerchantSettings,
    submerchants: SubMerchant[],
    emailAddresses: MerchantEmailAddress[],
    invitationReference: string,
};

export type Address = {
    addressLine1: string,
    addressLine2: string,
    city: string,
    country: string,
    postalCode: string,
};

export type ApiKey = {
    id: string,
    key: string,
};

export type MerchantSettings = {
    redirectButtonText: string,
    redirectURL: string,
    cardsEnabled: boolean,
    cardsAvailable: boolean,
    cardsRequested: boolean,
    previewFeatures: boolean,
    doNotInvoice: boolean,
};

export interface MerchantCharges {
    percentageCharge: number;
}

export type SubMerchant = {
    orgId: string,
    name: string,
    status: string,
    accounts: SubMerchantAccount[],
};

export type SubMerchantCreate = {
    name: string,
    email: string,
    roles: string[],
};

export type SubMerchantAccount = {
    accountId: string,
    roles: string[],
}

export type MerchantEmailAddress = {
    emailAddress: string;
    subscribedTemplates: string[];
};

export const GetMerchantAsync = async (accountId: string): Promise<Merchant | null> => {
    var response = await ApiGet(`merchants/${accountId}`);

    if (response.status === 200) {
        return response.data;
    }

    return null;
};

export const RecyclePrivateApiKey = async (accountId: string): Promise<ApiKey | null> => {
    var response = await ApiPost(
        `merchants/${accountId}/recycleApiKey`,
        {}
    );

    if (response.status === 200) {
        return response.data;
    }

    return null;
};

export const GetApiKeyAsync = async (id: string): Promise<ApiKey | null> => {
    var response = await ApiGet(`apikeys/${id}`);

    if (response.status === 200) {
        return response.data;
    }

    return null;
};

export const UpdateMerchantSettingsAsync = async (accountId: string, settings: MerchantSettings) => {
    var response = await ApiPut(`merchants/${accountId}/settings`, settings);
    return response.status === 200;
};

export const UpdateSubmerchantAsync = async (accountId: string, submerchant: SubMerchant) => {
    var response = await ApiPut(`merchants/${accountId}/submerchants/${submerchant.orgId}`, submerchant);
    return response.status === 200;
};

export const UpdateMerchantEmailAddressesAsync = async (accountId: string, emailAddresses: MerchantEmailAddress[]): Promise<boolean> => {
    var response = await ApiPut(`merchants/${accountId}/emailaddresses`, emailAddresses);
    return response.status === 200;
};

export const GetMerchantPartnerAsync = async (accountId: string): Promise<Partner | null> => {
    var response = await ApiGet(`merchants/${accountId}/partner`);
    if (response.status === 200) return response.data;
    return null;
};

export const UpdateMerchantPartnerAsync = async (accountId: string, partnerId: string): Promise<boolean> => {
    var response = await ApiPost(`merchants/${accountId}/changePartner?partnerId=${partnerId}`);
    return response.status === 200;
};

export const UploadSubMerchantsAsync = async (accountId: string, submerchants: SubMerchantCreate[]): Promise<ApiResponse> => {
    var response = await ApiPostResponse(`merchants/${accountId}/submerchants`, submerchants);
    return response;
};
