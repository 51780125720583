import React, {  Ref, forwardRef, useImperativeHandle, useState } from 'react';
import './FileDragDropUpload.css';
import { IonSpinner } from '@ionic/react';

export interface FileDragDropUploadRef {
    stopSpinning: Function,
}

export interface FileDragDropUploadProps {
    onChange: (file: File) => void,
}

export const FileDragDropUpload = forwardRef((props: FileDragDropUploadProps, ref: Ref<FileDragDropUploadRef>) => {
    const [dragOver, setDragOver] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    function stopSpinning() {
        setShowSpinner(false);
    }

    useImperativeHandle(ref, () => ({ stopSpinning }));

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setDragOver(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange(e.dataTransfer.files);
        }
    };

    const handleFileChange = (files: FileList | null) => {
        if (files && files[0]) {
            setShowSpinner(true);
            props.onChange(files[0]);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        handleFileChange(files);
    };

    return (
        <div
            className={`file-drop-area ${dragOver ? 'drag-over' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            {showSpinner
                ? <IonSpinner style={{height: "115px"}} />
                : <>
                    <input
                        type="file"
                        className="file-input"
                        onChange={handleInputChange}
                        accept=".csv"
                    />
                    <div className="drop-message">
                        Select a CSV file to upload or drag and drop it here
                    </div>
                    <div className="upload-icon"></div>
                </>
            }
        </div>
    );
});
