import { IonModal, IonTitle, IonToolbar, IonContent, IonItem, IonLabel, IonInput, IonSelectOption, IonSelect, IonButton, IonFooter, IonButtons, IonIcon, IonText } from "@ionic/react";
import { close } from 'ionicons/icons';

import { IonHeader } from "@ionic/react";
import { useState, useRef } from "react";
import { UserViewParams } from "../../User";
import { useParams } from "react-router";
import { UploadSubMerchantsAsync } from "../../../lib/data/Merchant";
import { AxiosError } from "axios";

export type SubMerchantCreateModalProps = {
    onClose: () => void;
}

export default function SubMerchantCreateModal({ onClose }: SubMerchantCreateModalProps) {
    const { userId } = useParams<UserViewParams>();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);
    const isCreating = useRef(false);

    const handleCreate = async () => {
        if (isCreating.current) return;
        isCreating.current = true;
        setError(null);

        try {
            var response = await UploadSubMerchantsAsync(userId, [{ name, email, roles: role ? [role] : [] }]);
            if (response.success) {
                onClose();
            } else {
                setError(response.message || 'Failed to create submerchant');
            }
        } catch (error) {
            setError((error as AxiosError).message || (error as string) || "Unknown error");
        } finally {
            isCreating.current = false;
        }
    }

    return <>
        <IonModal isOpen={true}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Create Submerchant</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p>Create a new submerchant.</p>
                <IonItem>
                    <IonLabel>Name</IonLabel>
                    <IonInput value={name} onIonChange={e => setName(e.detail.value!)} />
                </IonItem>
                <IonItem>
                    <IonLabel>Email</IonLabel>
                    <IonInput value={email} onIonChange={e => setEmail(e.detail.value!)} />
                </IonItem>
                <IonItem>
                    <IonLabel>Role</IonLabel>
                    <IonSelect value={role} onIonChange={e => setRole(e.detail.value)}>
                        <IonSelectOption></IonSelectOption>
                        <IonSelectOption value="admin">Admin</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonContent>
            <IonFooter>
                {error && <IonText color="danger">{error}</IonText>}
                <IonButton expand="block" onClick={handleCreate}>Create</IonButton>
                <IonButton expand="block" color="danger" onClick={onClose}>Cancel</IonButton>
            </IonFooter>
        </IonModal>
    </>;
}
