import { IonButton, IonFooter } from "@ionic/react";
import { IonCard, IonCardContent } from "@ionic/react";
import { SubMerchant } from "../../../lib/data/Merchant";
import { useState } from "react";
import SubMerchantModal from "./SubMerchantModal";
import SubMerchantImportModal from "./SubMerchantImportModal";
import SubMerchantCreateModal from "./SubMerchantCreateModal";

export type SubMerchantListProps = {
    submerchants: SubMerchant[];
    onChange: () => void;
}

export default function SubMerchantList({ submerchants, onChange }: SubMerchantListProps) {
    const [selectedSubmerchant, setSelectedSubmerchant] = useState<SubMerchant>();
    const [showImportModal, setShowImportModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    return <>
        <IonCard style={{ height: '100vh', maxHeight: '500px', display: 'flex', flexDirection: 'column' }}>
            <IonCardContent style={{ overflowY: 'auto', width: '100%' }}>
                <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>Submerchants</h2>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Name</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Status</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Accounts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {submerchants?.map((submerchant) => (
                            <tr key={submerchant.name}>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{submerchant.name}</td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{submerchant.status}</td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    <IonButton onClick={() => setSelectedSubmerchant(submerchant)}>View</IonButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </IonCardContent>
            <IonFooter style={{ padding: "15px" }}>
                <IonButton onClick={() => setShowImportModal(true)}>Import</IonButton>
                <IonButton onClick={() => setShowCreateModal(true)}>Create</IonButton>
            </IonFooter>
        </IonCard>
        {selectedSubmerchant &&
            <SubMerchantModal selectedSubmerchant={selectedSubmerchant}
                onClose={() => setSelectedSubmerchant(undefined)}
                onChange={onChange} />}
        {showImportModal && <SubMerchantImportModal onClose={() => setShowImportModal(false)} />}
        {showCreateModal && <SubMerchantCreateModal onClose={() => setShowCreateModal(false)} />}
    </>;
}
